import React from 'react'
import Campaginrequiemnt from './CreateCampaign/campaginrequiemnt'
import CreateCampaign from './CreateCampaign/CreateCampaign'

function MainCreateCampagin() {
  return (
    <div>
        {/* <Campaginrequiemnt/> */}
        <CreateCampaign/>
    </div>
  )
}

export default MainCreateCampagin

